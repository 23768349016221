import { useState, useEffect } from 'react';
import MarkdownViewer from './MarkdownViewer';
import "../css/article.css";
import { displayYAML } from '../utils/displayYAML';
import { useLocation, useParams } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export default function ArticleDisplay() {
    document.title = "Article"
    const location = useLocation();
    const articleData = location.state;

    const article = articleData.article;
    console.log(articleData);
    console.log("article is" + article);

    const [markdown, setMarkdown] = useState("");
    const [metaData, setMetadata] = useState({});

    useEffect(() => {
        if (typeof article === "string") {
            // If article is a URL/path string, fetch it
            fetch(article)
                .then((res) => res.text())
                .then((text) => {
                    const { markdown, metaData } = displayYAML(text);
                    setMarkdown(markdown);
                    setMetadata(metaData);
                });
        } else {
            // Otherwise, handle it as imported module content
            const { markdown, metaData } = displayYAML(article.default || "");
            setMarkdown(markdown);
            setMetadata(metaData);
        }
    }, [article]);
    console.log(metaData.categories);
    return (
        <article>
            <h1>{metaData.title}</h1>
            <div className="cat">
            {Array.isArray(metaData.categories) && metaData.categories.map((element, index) => (
                <p key={index}>
                    <span className="categories">{element}</span>
                </p>
            ))}
            </div>
            <p><strong>Author:</strong> {metaData.author}</p>
            <p><strong>Date:</strong> {metaData.date}</p>
            <MarkdownViewer markdownFile={article}></MarkdownViewer>
        </article>
    );    
}
