import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { stackoverflowLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// Create a function to load images from the assets folder
const importAllImages = (r) => {
    let images = {};
    r.keys().forEach((item) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
};

// Load all images in the assets folder
const images = importAllImages(require.context('../assets', false, /\.(png|jpe?g|svg|JPG|jpg)$/));

const MarkdownViewer = ({ markdownFile }) => {
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (markdownFile) {
            fetch(markdownFile)
                .then((response) => response.text())
                .then((text) => {
                    // Skip over YAML metadata at the start of the markdown file
                    const withoutYamlMetadata = removeYamlMetadata(text);
                    setContent(withoutYamlMetadata);
                });
        }
    }, [markdownFile]);

    // Function to remove YAML metadata from the start of the markdown content
    const removeYamlMetadata = (text) => {
        const yamlRegex = /^---\s*[\r\n]([\s\S]*?)\s*---\s*[\r\n]/;
        return text.replace(yamlRegex, '').trim(); // Remove YAML block
    };

    // Function to resolve image paths from Markdown
    const urlTransform = (uri) => {
        return images[uri] ? images[uri].default : uri; // Use the imported image if available, otherwise keep the original path
    };

    return (
        <main>
            <section>
                <ReactMarkdown
                    children={content}
                    urlTransform={urlTransform}
                    rehypePlugins={[rehypeRaw]} // Enable HTML within Markdown
                    remarkPlugins={[remarkGfm]} // Enable GitHub-flavored Markdown
                    components={{
                        code({ inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '');
                            return !inline && match ? (
                                <SyntaxHighlighter
                                    style={stackoverflowLight}
                                    language={match[1]}
                                    PreTag="div"
                                    {...props}
                                >
                                    {String(children).replace(/\n$/, '')}
                                </SyntaxHighlighter>
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            );
                        },
                    }}
                />
            </section>
        </main>
    );
};

export default MarkdownViewer;
