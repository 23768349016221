import Toc from "../components/TOC.js"
import Cards from "../components/cards.js"
import "../css/syllabus.css"

import powerbiLogo from '../assets/powerbilogo.png';
import pythonLogo from '../assets/pythonlogo.png';
import powerLogo from '../assets/powerautomate.png';
import aiBrain from '../assets/AI_brain.png';
import SQL from '../assets/db.png';
import statistics from '../assets/gaussicon_small.png';

export default function syllabus(){
    document.title = "Syllabus";
    return(
        <main className="syllabus">
            <section>
            <h1>Syllabus</h1>
            <p>Data is a continuously growing area covering all professions. This track aims giving Powercoders participants an entry point in the Data domain and the tools to continue learning after the Bootcamp. Data Track Participants compose their own specific program with courses from the broad <strong>Business Analytics</strong> and <strong>Data Science</strong> domains according to their preference, professional perspectives and previous programming knowledge. Click the images to access each topic individual page and detailed content.</p>
            <div className="flex" id="syllabusGridTitle">
            <h2>Business Analytics</h2>
            <h2>Data Science</h2>
            </div>
            <TechGrid />
            </section>
            
        </main>
    );
}

function TechGrid(){
    return(
        <div id="syllabusGrid">
        <Cards bg={powerbiLogo} overlay= "true" path="/materials/powerBI">
            <h3>Power BI</h3>
            <p>Handling small to large databases and creating highly impact full dashboards with Power BI. Low code approach with applications in all business domains such as Finance, Supply Chain or HR.</p>
        </Cards>
        <Cards bg={pythonLogo} overlay= "true" path="/materials/python">
            <h3>Python</h3>
            <p>Python in the most widely used programming language for Data Science, Data Engineering and Machine learning while being an universal language used in general applications such as websites, database and other applications.</p>
        </Cards>
        <Cards bg = {powerLogo} overlay= "true"  path="/materials/powerAutomate">
            <h3>Power Automate</h3>
            <p>PowerAutomate is our entry door for RPA which is a form of business process automation technology based on the concept of software robots or digital workers. These software-based bots can mimic many human user interactions with other systems.</p>
        </Cards>
        <Cards bg = {aiBrain} overlay= "true" path="/materials/aI">
            <h3>Artifical Intelligence</h3>
            <p>Conquer Artificial Intelligence (AI) frontier and realize the power of generative AI and complex large language models. Build a strong foundation with AI to propel you into the new AI-powered landscape.</p>
        </Cards>
        <Cards span="span2" bg = {SQL}>
            <h3>SQL</h3>
            <p>SQL remains a key competence for every data professional in both business and scientific domains. We provide initiation to the language and explore the connections with other languages and tools.</p>
        </Cards>
        <Cards span="span2" bg = {statistics} overlay= "true" path="/materials/statistics">
            <h3>Statistics</h3>
            <p>Statistics helps making sense of data by using concepts such as range and mean until advanced statistical inference and hypotheses testing. Applies to all skill levels and domains.</p>
        </Cards>
        </div>
    );
}