export function displayYAML(text) {
    const [rawMeta, extractedMetaData] = extractMetaData(text);

    // Remove the metadata section from the content
    const updatedText = text.replace(rawMeta[0], "");

    return { markdown: updatedText, metaData: extractedMetaData };
}

function extractMetaData(text) {
    const metaData = {};
    const metaRegExp = /^---[\r\n](((?!---).|[\r\n])*)[\r\n]---$/m;
    const rawMetaData = metaRegExp.exec(text);

    if (rawMetaData) {
        const keyValues = rawMetaData[1].split("\n");
        keyValues.forEach((keyValue) => {
            const [key, value] = keyValue.split(":");
            if (key && value !== undefined) {
                const trimmedValue = value.trim();

                // Check if value looks like an array (e.g., [Item1, Item2, Item3])
                if (trimmedValue.startsWith("[") && trimmedValue.endsWith("]")) {
                    // Remove brackets and split by commas, then trim each item
                    metaData[key.trim()] = trimmedValue
                        .slice(1, -1)
                        .split(",")
                        .map(item => item.trim());
                } else {
                    metaData[key.trim()] = trimmedValue;
                }
            }
        });
    }

    return [rawMetaData, metaData];
}
