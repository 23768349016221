//Import CSS
import './css/App.css';
//Import Components
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
//Import Pages
import Home from "./pages/home.js";
import Roadmap from "./pages/roadmap.js";
import Trainers from "./pages/trainers.js";
import Syllabus from "./pages/syllabus.js";
import Posts from "./pages/posts.js";
//import subpages
import PowerBI from "./pages/materials/powerBI.js";
import PowerAutomate from './pages/materials/powerAutomate.js';
import AI from './pages/materials/ai.js';
import Python from './pages/materials/python.js';
import Statistics from './pages/materials/statistics.js';
//Routing
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
      <Header />
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/roadmap" element={<Roadmap />}/>
          <Route path="/trainers" element={<Trainers />}/>
          <Route path="/syllabus" element={<Syllabus />}/>
          <Route path="/posts" element={<Posts />}/>
          <Route path="/materials/powerBI" element={<PowerBI />}/>
          <Route path="/materials/powerAutomate" element={<PowerAutomate />}/>
          <Route path="/materials/aI" element={<AI />}/>
          <Route path="/materials/python" element={<Python />}/>
          <Route path="/materials/statistics" element={<Statistics />}/>
          <Route path="/posts/*" element={<Posts />}/>
        </Routes>
      </Router>

      <Footer />
    </div>
  );
}
export default App;
