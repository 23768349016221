import "../css/cards.css";
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function Cards({children, span, bg, overlay, path}) {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    function handleMouseEnter() {
        if (overlay === "true") {
            setDropdownVisible(true);
        }
    }

    function handleMouseLeave() {
        if (overlay === "true") {
            setDropdownVisible(false);
        }
    }

    return (
        <div className={`cards ${span}`} 
             style={{ backgroundImage: `url(${bg})` }} 
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >
            {dropdownVisible && (
                <div className="overlay"><NavLink to={path}>Learn More</NavLink></div>
            )}
            {children}
            {overlay && (
            <p className="tapIndicator">Tap to learn more</p>
        )}
        </div>
    );
}
