import { NavLink } from "react-router-dom";
import "../css/posts.css";
import { displayYAML } from '../utils/displayYAML';
import { useState, useEffect } from 'react';

const posts = require.context('../posts', false, /\.md$/);
const postFiles = posts.keys().map((filename) => ({
    filename: filename.replace('./', '').replace('.md', ''),
    module: posts(filename)
}));

postFiles.sort().reverse();

function Posts() {
    const [postData, setPostData] = useState([]);

    useEffect(() => {
        const loadPosts = async () => {
            const data = await Promise.all(
                postFiles.map(async (post) => {
                    let markdown = "";
                    let metaData = {};

                    if (typeof post.module === "string") {
                        const res = await fetch(post.module);
                        const text = await res.text();
                        ({ markdown, metaData } = displayYAML(text));
                    } else {
                        ({ markdown, metaData } = displayYAML(post.module.default || ""));
                    }

                    return { markdown, metaData };
                })
            );

            setPostData(data);
        };

        loadPosts();
    }, []);

    return (
        <main id="postList">
            <section>
                <h1>Posts</h1>
                <p>News, events and announcements</p>
                <table id="articles">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Reading Time</th>
                            <th>Author</th>
                        </tr>
                    </thead>
                    <tbody>
                    {postData.map((post, index) => 
                        {
                       return (
                            <tr key={index}>
                                <td>{post.metaData.date}</td>
                                <td className="articleTitle"><NavLink 
  to={`/posts/${postFiles[index].filename}`} 
  state={{ article: postFiles[index].module }}
> 
                {post.metaData.title}
            </NavLink></td>
                                <td>{post.metaData.readingTime}</td>
                                <td>{post.metaData.author}</td>
                            </tr>
                        )})}
                    </tbody>
                </table>
            </section>
        </main>
    );
}

export default Posts;