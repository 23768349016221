import "../css/home.css";

export default function Home() {
    document.title = "Home";
    return(
        <div className="home">
                <h1 id="h1Home">Industry focus for <br></br><strong id="red">Powercoders Bootcamp</strong></h1>
                <p>The Data Track covers everything you need to know about data analysis and business intelligence. You’ll learn to work with large datasets and uncover insights in complex topics. This course teaches the essentials of data analysis and introduces powerful tools like PowerBI, Python, and Power Automate. You'll also dive into key topics like Artificial Intelligence and Statistics. Throughout, you’ll have the support of DataCamp, the leading platform for mastering data skills.</p>
            </div>
    );
}