import "../css/footer.css";
import Datacamp from "../assets/datacamp_donates_logo_white.png"

export default function Footer({Display}){
    return(
        <footer id="mainFooter">
            <p>2022-2024 J.Ramalho, P.Janes, Z.Loshi | supported by</p>
            <img src={Datacamp}/>
        </footer>
    );
}