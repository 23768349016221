import { useState, useEffect, useRef } from 'react';
import logo from "../assets/data-track-logo.png";
import "../css/header.css";
import { NavLink } from 'react-router-dom';
import Git from "./git.js";

export default function Header() {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [hoverSVG, setHoverSVG] = useState("#000000");
    const navWrapperRef = useRef(null);

    function handleMouseEnter() {
        setDropdownVisible(true);
    }
    function handleMouseLeave() {
        setDropdownVisible(false);
    }

    function handleMouseEnterSVG() {
        setHoverSVG("var(--pocoRed)");
    }
    function handleMouseLeaveSVG() {
        setHoverSVG("#000000");
    }

    function toggleMenu() {
        setMenuVisible(!menuVisible);
    }

    function closeMenu() {
        setMenuVisible(false);
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (navWrapperRef.current && !navWrapperRef.current.contains(event.target)) {
                closeMenu();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
      <header id="nav-background">
        <NavLink to="/" ><img id="nav-logo" src={logo} alt="logo"></img></NavLink>
        
        {/* Burger menu icon */}
        <div className={`burger-icon ${menuVisible ? 'burgerVisible' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        {/* Navigation links */}
        <div id="nav-link-wrapper" ref={navWrapperRef} className={menuVisible ? "visible" : ""}>
          <NavLink to="/roadmap" className={({ isActive }) => isActive ? "current" : "" } onClick={closeMenu}><nav>ROADMAP</nav></NavLink>
          <NavLink to="/syllabus" className={({ isActive }) => isActive ? "current" : "" } onClick={closeMenu}><nav>SYLLABUS</nav></NavLink>
          
          {/* Wrap the MATERIALS link and dropdown in a single container */}
          <div 
            className="nav-link-dropdown-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <NavLink
  to="/materials"
  className={({ isActive }) => (isActive ? "current" : "")}
  onClick={(e) => {
    // Prevent click action
    e.preventDefault();
    closeMenu();
  }}
  style={{ pointerEvents: "none" }}
>
  <nav className="dropDown">MATERIALS</nav>
</NavLink>

            {/* Dropdown menu */}
            {dropdownVisible && (
              <div className="dropdown-menu">
                <NavLink to="/materials/powerBI" className={({ isActive }) => isActive ? "current" : "" } onClick={closeMenu}><nav>Power BI</nav></NavLink>
                <NavLink to="/materials/powerautomate" className={({ isActive }) => isActive ? "current" : "" } onClick={closeMenu}><nav>Power Automate</nav></NavLink>
                <NavLink to="/materials/python" className={({ isActive }) => isActive ? "current" : "" } onClick={closeMenu}><nav>Python</nav></NavLink>
                <NavLink to="/materials/ai" className={({ isActive }) => isActive ? "current" : "" } onClick={closeMenu}><nav>Artificial Intelligence</nav></NavLink>
                <NavLink to="/materials/statistics" className={({ isActive }) => isActive ? "current" : "" } onClick={closeMenu}><nav>Statistics</nav></NavLink>
              </div>
            )}
          </div>

          <NavLink to="/posts" className={({ isActive }) => isActive ? "current" : "" } onClick={closeMenu}><nav>POSTS</nav></NavLink>
          <NavLink to="/trainers" className={({ isActive }) => isActive ? "current" : "" } onClick={closeMenu}><nav>TRAINERS</nav></NavLink>
          <a onMouseEnter={handleMouseEnterSVG} onMouseLeave={handleMouseLeaveSVG} href="https://github.com/Powercoders-International" target='_blank' onClick={closeMenu}>
          <Git fillColor={hoverSVG} h="25px" w="25px"></Git></a>
        </div>
      </header>
    );
}
