import LinkedinLogo from "../assets/LinkedinLogo.png";
import Cards from "../components/cards.js"

export default function TrainerCards (props){
    return(
        //return a component that can be filled with Trainer data
        <Cards>
            <h2>{props.Name}</h2>
            <img src={props.Portrait} className="trainersPortrait"/>
            <p><strong>{props.Title}</strong></p>
            <a href={`https://${props.CompanyWeb}`} target="_blank"><strong>{props.Employer}</strong></a>
            <p>{props.Tech}</p>
            <p>{props.Supports}</p>
            <BookMe booking = {props.booking}/>
            <footer class="card-footer">
            <a href={props.Linkedin} target="_blank"><img className = "linkedinLogo" src={LinkedinLogo}/></a>
            </footer>
            </Cards>
    );
}

//check if a link to booking page exists
function BookMe({booking}){
    if(booking != ""){
        return(
            <a href = {booking} target = "_blank"><button className="bookButton" type="button">Book time with me</button></a>
        );
    }
    else {
        return (<a><button className="bookButton placeholderButton" type="button">Book time with me</button></a>);
    }
}