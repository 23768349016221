import PostList from "../components/postList"
import ArticleDisplay from "../components/articleDisplay";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

export default function posts(){
  document.title = "Posts";
    return(<Routes>
        <Route path="/" element={<PostList />} />                {/* Renders at /posts */}
        <Route path=":postId" element={<ArticleDisplay />} />    {/* Renders at /posts/:postId */}
      </Routes>);
}