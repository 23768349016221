import * as Data from "../assets/trainers_profiles.json"; //import the trainer data to fill the cards
import TrainerCards from "../components/TrainerCards.js";
import "../css/trainers.css";

// Create a context to load images
const importAll = (r) => {
    let images = {};
    r.keys().map((item) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
};

const images = importAll(require.context('../assets', false, /\.(png|jpe?g|svg|JPG|jpg)$/));

function TrainerCardGrid() { //itteraitng over the trainers_profiles.json file and gnerate the cards
    return (
        <div id="trainers-grid">
      {Object.keys(Data)
        .filter((key) => key !== "default")  // Filter out the "default" key
        .map((key) => {
          const trainer = Data[key];
          return (
            <TrainerCards  
               Name={key} 
               Portrait={images[trainer.picture]} 
               Title={trainer.title} 
               Employer = {trainer.employer}
               CompanyWeb = {trainer.web}
               Tech = {trainer.technologies}
               Supports = {trainer.supports}
               booking = {trainer.booking}
               Linkedin = {trainer.linkedin}
               />);
})}
        </div>
    );
}

export default function Trainers() { //the full page to be displayed
    document.title = "Trainers";
    return (
        <main className="trainers">
            <h1>Trainers</h1>
            <p className="addmarginBottom">
                Our team of volunteers has experienced professionals from important
                companies in Switzerland and brings extensive experience in the data
                domain.
                <br />
                Powercoders participants are welcome to reach out for questions and for
                booking individual support meetings when needed (LinkedIn and Calendar
                links below).
            </p>
            <TrainerCardGrid />
        </main> 
    );
}
